import React from 'react';
import { ForceGraph3D } from 'react-force-graph';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import backgroundData from './data.json';
import Stack from 'react-bootstrap/Stack';
import logoWhite from './logo-white.svg'
import { BsGithub } from "react-icons/bs";

import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import "./App.css"

// https://stackoverflow.com/a/38463360
const BackgroundGraph = styled.section`
    width: 100vw;
    height: 100vh;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
`;

function App() {

  const { useRef, useEffect } = React;

  const fgRef = useRef();

  useEffect(() => {
    const bloomPass = new UnrealBloomPass();
    bloomPass.strength = 1;
    bloomPass.radius = 1;
    bloomPass.threshold = 0;
    fgRef.current.postProcessingComposer().addPass(bloomPass);
  }, []);

  return (
      <>
        <style type="text/css">
          {`
          .dropdown>a::after {
            color: white;
          }
          .dropdown-menu {
            background-color: transparent;
            .dropdown-item:hover {
              text-decoration: underline;
              color: #fff;
              background-color: transparent;
            }
          }
        `}
        </style>

        {/* https://stackoverflow.com/a/69321054 */}
        <Stack direction="horizontal" gap={5} className='mt-5 mx-5'>
          <div className="p-2 text-white">
            <img src={logoWhite} alt='logo'/>
            Paion Data
          </div>

          <Nav className="justify-content-center">
            <NavDropdown title={
              <span style={{color: 'white'}}>C 端应用</span>
            } id="basic-nav-dropdown">
              <NavDropdown.Item href="https://github.com/paion-data/wilhelm">
                <span style={{color: 'white'}}>Wilhelm</span>
              </NavDropdown.Item>
              <NavDropdown.Item href="#">
                <span style={{color: 'white'}}>Nexus Graph</span>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={
              <span style={{color: 'white'}}>自然语言处理</span>
            } id="basic-nav-dropdown">
              <NavDropdown.Item href="https://huggingface.co/datasets/paion-data/wiktionary-data">
                <span style={{color: 'white'}}>Wiktionary Data</span>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>

          <div className="p-2 ms-auto text-white">
            <a href="https://github.com/paion-data" style={{color: '#ffffff'}}>
              <BsGithub
                  size={30}
                  onMouseOver={({target}) => target.style.color = '#0C6EFD'}
                  onMouseOut={({target}) => target.style.color = '#ffffff'}
              />
            </a>
          </div>
        </Stack>

        {/* https://stackoverflow.com/a/52284399 */}
        <h2 data-text="语言带领我们找到这个世界中属于我们自己的未知和精彩">
          <div className="headingText">语言带领我们找到这个世界中属于我们自己的未知和精彩</div>
        </h2>


        <div style={{position: 'absolute', left: '47%', bottom: "2%"}}>
          <a href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2024234224</a>
        </div>

        <BackgroundGraph>
          <ForceGraph3D
              ref={fgRef}
              backgroundColor="#000003"
              graphData={backgroundData}
              nodeLabel={node => `${node.user}: ${node.description}`}
              nodeAutoColorBy="user"
              linkDirectionalParticles={1}
          />
        </BackgroundGraph>
      </>
  );
}

export default App;
